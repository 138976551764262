import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field } from 'react-final-form';

import { Grid, Box, Button, Container, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import { composeValidators, length, numbers, required } from 'validators/validationInputFields';

import TextField from 'inputs/forms/TextField';
import DatePickerField from 'inputs/forms/DatePickerField';
import SelectField from 'inputs/forms/SelectField';

import PromptConfirmationPopup from 'components/popups/PromptConfirmationDialog';

import { getSexOptions } from 'data/sex';

import { parseDateFiled } from 'utils/formatterUtils';

const MIN_MAX_LENGTH_1_32 = length(1, 32);

const styles = () => ({
    addPatientsBox: {
        marginTop: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        boxShadow: '0 2px 15px 0 #E6EAED',
        height: '100%',
        padding: '40px 24px',
    },
    container: {
        padding: '0 150px 40px 150px',
        marginTop: 40,
    },
    addPatientFormLink: {
        textDecoration: 'none',
    },
    patientFormBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
    },
    patientFormChild: {
        width: '50%',
        height: '60px',
    },
    submitButton: {
        float: 'right',
        position: 'relative',
        bottom: 32,
        right: 40,
    },
    deleteButton: {
        float: 'right',
        position: 'relative',
        bottom: 32,
        right: 60,
    },
});

const Patient = props => {
    const sex = useMemo(getSexOptions, []);

    useEffect(() => () => props.onChangePristineForm({ pristine: true }), []);

    useEffect(() => {
        props.onChangePristineForm({ pristine: props.pristine });
    }, [props.pristine]);

    useEffect(() => {
        if (props.submitSucceeded) {
            props.onSuccessSubmit();
        }
    }, [props.submitSucceeded]);

    const handleLeave = useCallback(location => {
        if (!props.pristine && !props.submitSucceeded) {
            props.showPromptPopup(location);
            return false;
        }
        return true;
    }, [props.pristine, props.submitSucceeded]);

    return (
        <Fragment>
            <PromptConfirmationPopup
                text={l('Are you sure you want to leave the screen without saving?')}
                location={props.promptLocation}
                isOpenPopup={props.isOpenPromptConfirmPopup}
                onClickOk={props.onClickLeave}
                onClickCancel={props.onClickStay}
                onLeave={handleLeave}
            />
            <Container className={props.classes.container}>
                <Box className={props.classes.addPatientFormLink} onClick={props.onClickPatientLink}>
                    <IconButton type="submit" className={props.classes.iconButton} aria-label="search">
                        <ArrowBackIosIcon />
                    </IconButton>
                </Box>
                <Box className={props.classes.addPatientsBox}>
                    <Box textAlign="center">
                        <Typography noWrap variant="h3">
                            {props.values.id && props.isShared && l('Patient\'s info (shared)')}
                            {props.values.id && !props.isShared && l('Patient\'s info')}
                            {!props.values.id && !props.isShared && l('Add patient')}
                        </Typography>
                    </Box>
                    <form onSubmit={props.handleSubmit}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="text"
                            className={props.classes.submitButton}
                            disabled={props.isShared}
                        >
                            {l('Save')}
                        </Button>
                        {
                            props.values.id && (
                                <Button
                                    color="secondary"
                                    variant="text"
                                    className={props.classes.deleteButton}
                                    onClick={props.onClickDelete}
                                    disabled={props.isShared}
                                >
                                    {l('Delete')}
                                </Button>
                            )
                        }
                        <Container className={props.classes.patientFormBox}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="patientId"
                                        validate={composeValidators(required, MIN_MAX_LENGTH_1_32)}
                                        placeholder={l('Patient ID*')}
                                        component={TextField}
                                        disabled={props.isShared}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="flex-end"
                                >
                                    <Grid item xs={4}>
                                        <Field
                                            openTo="year"
                                            disableFuture
                                            placeholder={l('Birthday*')}
                                            name="dateOfBirth"
                                            parse={parseDateFiled}
                                            validate={required}
                                            component={DatePickerField}
                                            disabled={props.isShared}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box ml={2} pb={props.isShared ? 0 : '24px'}>
                                            {props.isShared ? (
                                                <Field
                                                    disabled
                                                    fullWidth
                                                    name="gender"
                                                    placeholder={l('Sex*')}
                                                    component={TextField}
                                                />
                                            ) : (
                                                <Fragment>
                                                    <Typography variant="body2" color="secondary" noWrap>
                                                        {l('Sex*')}
                                                    </Typography>
                                                    <Field
                                                        fullWidth
                                                        name="gender"
                                                        values={sex}
                                                        component={SelectField}
                                                        placeholder={l('Sex*')}
                                                        validate={required}
                                                        disabled={props.isShared}
                                                    />
                                                </Fragment>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box ml={2}>
                                            <Field
                                                fullWidth
                                                name="weight"
                                                validate={composeValidators(required, numbers)}
                                                placeholder={l('Weight*')}
                                                component={TextField}
                                                disabled={props.isShared}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </form>
                </Box>
            </Container>
        </Fragment>
    );
};

Patient.propTypes = {
    submitSucceeded: PropTypes.bool,
    values: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    pristine: PropTypes.bool.isRequired,
    isShared: PropTypes.bool.isRequired,
    promptLocation: ImmutablePropTypes.map,
    showPromptPopup: PropTypes.func.isRequired,
    isOpenPromptConfirmPopup: PropTypes.bool.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickStay: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onSuccessSubmit: PropTypes.func.isRequired,
    onClickPatientLink: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
};

export default withStyles(styles)(Patient);
