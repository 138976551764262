import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import { DATE } from 'utils/formatterUtils';

import CalendarIcon from 'assets/img/calendar.svg';

const styles = () => ({
    defaultStyle: {
        paddingBottom: 24,
    },
    icon: {
        cursor: 'pointer',
        padding: 8,
        height: 24,
    },
});

const getValue = value => {
    if (!value) {
        return null;
    }
    return moment.utc(value);
};

const InputDatePicker = ({
    value,
    label,
    classes,
    className,
    error,
    dateFormat,
    onChange,
    ...custom
}) => {
    const [date, setDate] = useState(null);

    useEffect(() => {
        setDate(value);
    }, [value]);

    const handleChange = useCallback(onChange, [onChange]);

    const handleYearChange = useCallback(val => {
        setDate(val.format());
    }, []);

    const errorText = typeof error === 'string' ? error : undefined;
    return (
        <DatePicker
            autoOk
            clearable
            fullWidth
            error={!!errorText}
            helperText={errorText}
            className={cx(className, { [classes.defaultStyle]: !errorText })}
            format={dateFormat}
            {...custom}
            InputProps={{
                endAdornment: (
                    <img className={classes.icon} src={CalendarIcon} alt="calendar" />
                ),
            }}
            InputLabelProps={{
                shrink: true,
            }}
            value={getValue(date)}
            clearLabel={l('Clear')}
            cancelLabel={l('Cancel')}
            onChange={handleChange}
            onYearChange={handleYearChange}
        />
    );
};

InputDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string),
    dateFormat: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
};

InputDatePicker.defaultProps = {
    dateFormat: DATE,
    views: ['year', 'month', 'date'],
};

export default withStyles(styles)(InputDatePicker);
