import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import Immutable from 'immutable';
import cx from 'classnames';

import { Box, Button, Container, Divider, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Delete as DeleteIcon,
    Pause as PauseIcon,
    PersonAddOutlined as PersonAddOutlinedIcon,
    PlayArrow as PlayArrowIcon,
    Sync as SyncIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import HeartPulse from 'assets/img/pulse.svg';
import ConfirmedIcon from 'assets/img/confirmed.svg';
import NotConfirmedIcon from 'assets/img/notConfirmed.svg';
import SyncSuccessIcon from 'assets/img/success_sync.svg';

import SelectInput from 'inputs/SelectInput';

import useAudio from 'hooks/useAudio';

import { getOrgans, HEART } from 'data/organs';
import { SUCCESS, SYNCING } from 'data/syncStatuses';

import { DATE } from 'utils/formatterUtils';

const styles = () => ({
    recordsBox: {
        marginTop: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        boxShadow: '0 2px 15px 0 #E6EAED',
        height: 193,
        padding: '0 24px 40px 24px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    recordsHeader: {
        marginLeft: 18,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    divider: {
        width: 'calc(100% + 48px)',
        margin: '0px 0 0 -24px',
    },
    bottomArea: {
        marginTop: 30,
    },
    playIcon: {
        fontSize: 28,
    },
    auscultationArea: {
        width: 250,
    },
    formControl: {
        marginTop: 5,
    },
    organPosition: {
        marginTop: 10,
    },
    organContainer: {
        width: 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actions: {
        marginTop: 15,
    },
    button: {
        float: 'right',
        marginLeft: 5,
    },
    confirmResult: {
        float: 'right',
        marginLeft: 5,
    },
    opacity: {
        opacity: '0.5',
    },
    rotate: {
        animationName: 'rotation',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
    },
    fullName: {
        color: '#51B3BD',
        fontWeight: 'bold',
    },
    heartPulsContainer: {
        margin: '0 10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: 20,
    },
    pulseIcon: {
        width: 25,
        color: 'red',
        margin: '0 5px 0',
    },
});

const Record = props => {
    const [record, setRecord] = useState(props.record);
    const [playing, toggle] = useAudio(props.record.getIn(['file', 'location']));
    const organs = useMemo(getOrgans, []);

    const isConfirmed = record.has('isConfirmed') ? record.get('isConfirmed') : Immutable.is(props.record, record);

    const isDisabledPatientBtn = props.isShared || props.syncStatus === SYNCING;

    useEffect(() => {
        props.onChangeConfirm(record.get('id'), isConfirmed);
    }, [isConfirmed]);

    useEffect(() => {
        if (!Immutable.is(props.record, record)) {
            setRecord(props.record);
        }
    }, [props.record]);

    const handleChange = useCallback(e => {
        const { name, value } = e.target;
        setRecord(prevState => prevState.set(name, value));
    }, []);

    const handleClickDelete = useCallback(e => {
        e.stopPropagation();
        props.onClickDelete(props.record);
    }, [props.record, props.onClickDelete]);

    const handleClickConfirm = useCallback(e => {
        e.stopPropagation();
        props.onClickConfirm(record.delete('isConfirmed'));
    }, [record, props.onClickConfirm]);

    const handleClickSync = useCallback(e => {
        e.stopPropagation();
        props.onClickSync(record);
    }, [record, props.onClickSync]);

    const handleClickNotes = useCallback(e => {
        e.stopPropagation();
        props.onClickNotes(record);
    }, [record, props.onClickNotes]);

    const handleClickAssignPatient = useCallback(e => {
        e.stopPropagation();
        props.onClickAssignPatient(record);
    }, [record, props.onClickAssignPatient]);

    const handleClickUnassignPatient = useCallback(e => {
        e.stopPropagation();
        setRecord(prevState => prevState.set('patient', null));
    }, []);

    const handleClickPatient = useCallback(e => {
        e.stopPropagation();
        props.onClickPatient(record.get('patient'));
    }, [record, props.onClickPatient]);

    let syncIcon;
    switch (props.syncStatus) {
        case SYNCING:
            syncIcon = <SyncIcon fontSize="large" className={props.classes.rotate} />;
            break;
        case SUCCESS:
        default:
            syncIcon = props.isLocal || !isConfirmed
                ? <SyncIcon fontSize="large" />
                : <img src={SyncSuccessIcon} alt="sync_all" />;
            break;
    }

    let assignPatient = null;
    if (props.onClickAssignPatient) {
        assignPatient = record.get('patient') ? (
            <Box
                ml={2}
                width="180px"
                alignItems="center"
                display="inline-flex"
                className={props.classes.actions}
            >
                <IconButton
                    color="secondary"
                    disabled={isDisabledPatientBtn}
                    className={props.classes.button}
                    onClick={handleClickUnassignPatient}
                >
                    <ClearIcon />
                </IconButton>
                <Tooltip title={record.getIn(['patient', 'patientId'], '-')} leaveDelay={1000} placement="bottom-end">
                    <Button
                        color="primary"
                        variant="text"
                        onClick={handleClickPatient}
                    >
                        <Typography noWrap className={props.classes.fullName}>
                            {record.getIn(['patient', 'patientId'], '-')}
                        </Typography>
                    </Button>
                </Tooltip>
            </Box>
        ) : (
            <Box
                ml={2}
                width="180px"
                alignItems="center"
                display="inline-flex"
                className={props.classes.actions}
            >
                <Button
                    color="primary"
                    variant="text"
                    className={props.classes.button}
                    startIcon={<PersonAddOutlinedIcon />}
                    disabled={isDisabledPatientBtn}
                    onClick={handleClickAssignPatient}
                >
                    {l('Add patient')}
                </Button>
            </Box>
        );
    }
    return (
        <Container className={props.classes.recordsBox}>
            <Box className={props.classes.recordsHeader}>
                <Typography noWrap variant="body2" color="secondary">
                    {moment(record.get('createdAt')).format(DATE)}
                </Typography>
                <Box ml={4}>
                    <Button variant="text" color="primary" onClick={handleClickNotes}>
                        {l('Notes')}
                    </Button>
                </Box>
                {
                    props.isShared && (
                        <Box ml={4}>
                            <Typography noWrap color="secondary">
                                {l('Shared with you')}
                            </Typography>
                        </Box>
                    )
                }
                <Box ml="auto" className={props.classes.deleteIcon}>
                    <IconButton color="primary" disabled={props.isShared} onClick={handleClickDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
            <Divider className={props.classes.divider} />
            <Box display="flex" className={props.classes.bottomArea}>
                <Box alignItems="center" display="flex" className={props.classes.organPosition}>
                    <IconButton color="primary" onClick={toggle}>
                        {
                            playing
                                ? <PauseIcon className={props.classes.playIcon} />
                                : <PlayArrowIcon className={props.classes.playIcon} />
                        }
                    </IconButton>
                    <Box ml={4} className={props.classes.organContainer}>
                        <Typography variant="body1">
                            {organs[record.get('organ')]}
                        </Typography>
                        {
                            record.get('pulse') &&
                            (
                                <Box ml={4} className={props.classes.heartPulsContainer}>
                                    <img src={HeartPulse} alt="pulse" className={props.classes.pulseIcon} />
                                    <Typography noWrap variant="body2">
                                        {record.get('pulse')}
                                    </Typography>
                                </Box>
                            )
                        }
                    </Box>
                </Box>
                <Box alignItems="center" display="flex" ml={2}>
                    <SelectInput
                        label={l('Auscultation area')}
                        values={
                            record.get('organ') === HEART
                                ? props.auscultationAreaHeartOptions
                                : props.auscultationAreaLungOptions
                        }
                        name="auscultationArea"
                        className={`${props.classes.formControl} ${props.classes.auscultationArea}`}
                        value={record.get('auscultationArea')}
                        disabled={props.isShared}
                        onChange={handleChange}
                    />
                </Box>
                <Box alignItems="center" display="flex" ml={2}>
                    <SelectInput
                        label={l('Results')}
                        values={
                            record.get('organ') === HEART
                                ? props.resultAreaHeartOptions
                                : props.resultAreaLungOptions
                        }
                        name="assessmentFinding"
                        className={props.classes.formControl}
                        value={
                            record.get('assessmentFinding')
                                ? record.get('assessmentFinding')
                                : record.get('recognitionResult')
                        }
                        disabled={props.isShared}
                        onChange={handleChange}
                    />
                </Box>
                {assignPatient}
                <Box
                    alignItems="center"
                    display="flex"
                    ml={2}
                    width="150px"
                    className={props.classes.actions}
                >
                    <Button
                        color="primary"
                        variant="text"
                        className={cx(
                            props.classes.button,
                            { [props.classes.opacity]: !props.isLocal },
                        )}
                        startIcon={syncIcon}
                        disabled={!isConfirmed}
                        onClick={props.isLocal ? handleClickSync : null}
                    >
                        {props.isLocal || !isConfirmed ? l('Sync record') : l('Synced')}
                    </Button>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    ml={2}
                    className={props.classes.actions}
                >
                    <Button
                        color={isConfirmed ? 'primary' : 'secondary'}
                        variant="text"
                        className={cx(
                            props.classes.confirmResult,
                            { [props.classes.opacity]: isConfirmed },
                        )}
                        startIcon={(
                            <img
                                src={isConfirmed ? ConfirmedIcon : NotConfirmedIcon}
                                alt="confirmedIcon"
                            />
                        )}
                        onClick={isConfirmed ? null : handleClickConfirm}
                    >
                        {!isConfirmed ? l('Confirm result') : l('Confirmed')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

Record.propTypes = {
    isLocal: PropTypes.bool,
    isShared: PropTypes.bool,
    record: ImmutablePropTypes.map.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    syncStatus: PropTypes.oneOf([SUCCESS, SYNCING]),
    auscultationAreaHeartOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    auscultationAreaLungOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    resultAreaLungOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    resultAreaHeartOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    onClickSync: PropTypes.func,
    onClickAssignPatient: PropTypes.func,
    onClickNotes: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickPatient: PropTypes.func.isRequired,
    onChangeConfirm: PropTypes.func.isRequired,
};

export default withStyles(styles)(memo(Record));
