import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    defaultCss: {
        paddingBottom: 24,
    },
});

const Field = ({
    input,
    label,
    hint,
    required,
    meta: { touched, error },
    innerError,
    className,
    classes,
    helperText,
    InputProps,
    InputLabelProps,
    ...custom
}) => {
    const handleBlur = useCallback(event => {
        const { relatedTarget } = event;
        if (relatedTarget && (relatedTarget.getAttribute('type') === 'button')) {
            event.preventDefault();
        } else {
            input.onBlur(event);
        }
    }, [input.onBlur]);

    const errorText = typeof (error || innerError) === 'string' ? (innerError || error) : undefined;
    return (
        <TextField
            error={touched && (!!error || !!innerError)}
            helperText={touched && errorText}
            className={cx(className, { [classes.defaultCss]: !touched || (!error && !innerError) })}
            {...input}
            {...custom}
            InputProps={{ ...InputProps }}
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            onBlur={handleBlur}
        />
    );
};

Field.propTypes = {
    input: PropTypes.object, // eslint-disable-line
    label: PropTypes.string,
    hint: PropTypes.node,
    required: PropTypes.bool,
    helperText: PropTypes.node,
    className: PropTypes.string,
    innerError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    classes: PropTypes.objectOf(PropTypes.string),
    meta: PropTypes.object, // eslint-disable-line
    InputProps: PropTypes.object, // eslint-disable-line
    InputLabelProps: PropTypes.object, // eslint-disable-line
};

export default withStyles(styles)(Field);
