import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field } from 'react-final-form';
import moment from 'moment';

import { Box, Button, Paper, Typography, FormLabel, FormHelperText, IconButton } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import PromptConfirmationPopup from 'components/popups/PromptConfirmationDialog';

import TextField from 'inputs/forms/TextField';

import { length, required, composeValidators, specialSymbols } from 'validators/validationInputFields';

import { DATE } from 'utils/formatterUtils';

const MIN_MAX_LENGTH_2_255 = length(2, 255);

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    paper: {
        width: 600,
        minHeight: 350,
        padding: '20px 10px',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.14)',
        borderRadius: 2,
    },
    label: {
        marginRight: 16,
    },
    textLength: {
        position: 'relative',
        left: '90%',
        top: -25,
    },
});

const Notes = props => {
    useEffect(() => () => props.onChangePristineForm({ pristine: true }), []);

    useEffect(() => {
        props.onChangePristineForm({ pristine: props.pristine });
    }, [props.pristine]);

    const handleLeave = useCallback(location => {
        if (!props.pristine) {
            props.showPromptPopup(location);
            return false;
        }
        return true;
    }, [props.pristine]);

    return (
        <Fragment>
            <PromptConfirmationPopup
                text={l('Are you sure you want to leave the screen without saving?')}
                location={props.promptLocation}
                isOpenPopup={props.isOpenPromptConfirmPopup}
                onClickOk={props.onClickLeave}
                onClickCancel={props.onClickStay}
                onLeave={handleLeave}
            />
            <Box my={4} ml={16}>
                <IconButton onClick={props.goBack}>
                    <ArrowBackIcon />
                </IconButton>
            </Box>
            <Box className={props.classes.container}>
                <Paper className={props.classes.paper}>
                    <Box my={2} textAlign="center">
                        <Typography variant="h3">
                            {l('Notes')}
                        </Typography>
                    </Box>
                    <Box mt={4}>
                        <form onSubmit={props.handleSubmit}>
                            <Box px={12} mb={2}>
                                <Typography>
                                    <FormLabel
                                        className={props.classes.label}
                                        component="span"
                                    >
                                        {l('Patient')}
                                    </FormLabel>
                                    {
                                        props.patient
                                            ? props.patient.get('patientId')
                                            : '-'
                                    }
                                </Typography>
                            </Box>
                            <Box px={12} mb={2}>
                                <Typography>
                                    <FormLabel
                                        className={props.classes.label}
                                        component="span"
                                    >
                                        {l('Patient D.O.B.')}
                                    </FormLabel>
                                    {props.patient ? moment(props.patient.get('dateOfBirth')).format(DATE) : '-'}
                                </Typography>
                            </Box>
                            <Box px={12} mb={2}>
                                <Typography>
                                    <FormLabel
                                        className={props.classes.label}
                                        component="span"
                                    >
                                        {l('Clinician')}
                                    </FormLabel>
                                    {props.doctorFullName}
                                </Typography>
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    multiline
                                    name="comment"
                                    validate={composeValidators(required, specialSymbols, MIN_MAX_LENGTH_2_255)}
                                    placeholder={l('No comments yet...')}
                                    component={TextField}
                                />
                                <FormHelperText className={props.classes.textLength}>
                                    {props.values.comment ? props.values.comment.length : 0}/255
                                </FormHelperText>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={props.submitting || props.pristine}
                                >
                                    {l('Save')}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Paper>
            </Box>
        </Fragment>
    );
};

Notes.propTypes = {
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    patient: ImmutablePropTypes.map,
    promptLocation: ImmutablePropTypes.map,
    isOpenPromptConfirmPopup: PropTypes.bool,
    values: PropTypes.shape({ comment: PropTypes.string }),
    doctorFullName: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    goBack: PropTypes.func.isRequired,
    onClickStay: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
};

export default withStyles(styles)(Notes);
